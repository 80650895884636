export function initializeAlbumCards()
{
	Array.from(document.querySelectorAll("a.album"))
		.forEach(album => initializeAlbumCard(album));
}

export function initializeAlbumCard(album)
{
	const delay = parseInt(album.dataset.delay || "0");
	const interval = parseInt(album.dataset.interval || "5000");
	const images = Array.from(album.querySelectorAll(".album-preview img"));

	let currentIndex = -1;

	const roll = () =>
	{
		currentIndex++;

		if (currentIndex >= images.length)
			currentIndex = 0;

		images.forEach((image, index) => index !== currentIndex ? image.classList.remove("is-current") : undefined);
		images[currentIndex].classList.add("is-current");

		setTimeout(() => requestAnimationFrame(roll), interval);
	};

	requestAnimationFrame(() => album.classList.add("is-ready"));

	setTimeout(() => requestAnimationFrame(roll), delay);
}
